var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-row",
        { attrs: { gutter: 8 } },
        [
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("j-vxe-table", {
                staticStyle: { "margin-bottom": "8px" },
                attrs: {
                  toolbar: "",
                  "row-number": "",
                  "row-selection": "",
                  "click-select-row": "",
                  "highlight-current-row": "",
                  "radio-config": { highlight: false },
                  "checkbox-config": { highlight: false },
                  height: 357,
                  loading: _vm.table1.loading,
                  columns: _vm.table1.columns,
                  dataSource: _vm.table1.dataSource,
                  pagination: _vm.table1.pagination,
                },
                on: {
                  pageChange: _vm.handleTable1PageChange,
                  selectRowChange: _vm.handleTable1SelectRowChange,
                },
              }),
              _c("j-vxe-table", {
                attrs: {
                  toolbar: "",
                  "row-number": "",
                  "row-selection": "",
                  "click-select-row": "",
                  height: 356,
                  loading: _vm.table2.loading,
                  columns: _vm.table2.columns,
                  dataSource: _vm.table2.dataSource,
                  pagination: _vm.table2.pagination,
                },
                on: { pageChange: _vm.handleTable2PageChange },
              }),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("j-vxe-table", {
                staticStyle: { "margin-top": "40px" },
                attrs: {
                  "row-number": "",
                  height: 800,
                  columns: _vm.table1.columns,
                  dataSource: _vm.table1.selectedRows,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }